var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { apiUrl } from "Static/variables";
import { changeSnackbarData } from "Redux/slices/appSlice";
import { deleteCookie } from "Utils/deleteCookie";
import { canUseWebP } from "Utils/canUseWebP";
var isSupportWebp = canUseWebP();
export var fetchApi = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
    var store, token, config;
    var body = _a.body, credentials = _a.credentials, headers = _a.headers, _b = _a.hideErrorSnackbar, hideErrorSnackbar = _b === void 0 ? false : _b, method = _a.method, url = _a.url, otherConfigs = __rest(_a, ["body", "credentials", "headers", "hideErrorSnackbar", "method", "url"]);
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, import("Redux/store")];
            case 1:
                store = _c.sent();
                token = localStorage.getItem("token") || "";
                if (token === "1928374650")
                    return [2, Promise.reject()];
                config = __assign({ headers: __assign(__assign({ Authorization: token, "Content-Type": "application/json", "X-Platform": "web" }, (isSupportWebp && { "X-Support-Webp": "1" })), headers), credentials: credentials || "include" }, otherConfigs);
                if (method !== "GET" && method !== "DELETE" && body)
                    config.body = JSON.stringify(body);
                return [2, fetch(apiUrl + url, __assign({ method: method }, config))
                        .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                        var data, errMsg;
                        var _a;
                        var _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    if (!!res.ok) return [3, 2];
                                    return [4, res.json()];
                                case 1:
                                    data = _c.sent();
                                    errMsg = res.statusText ||
                                        (data === null || data === void 0 ? void 0 : data.message) ||
                                        (data.length > 0 && ((_b = data[0]) === null || _b === void 0 ? void 0 : _b.message)) ||
                                        "Request failed with status code ".concat(res.status);
                                    throw {
                                        code: res.status,
                                        message: errMsg,
                                        name: data.name || "FetchError",
                                        url: res.url,
                                    };
                                case 2:
                                    _a = {};
                                    return [4, res.json()];
                                case 3: return [2, (_a.data = _c.sent(),
                                        _a.status = res.status,
                                        _a.statusText = res.statusText,
                                        _a.headers = res.headers,
                                        _a)];
                            }
                        });
                    }); })
                        .catch(function (err) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (err.name === "AbortError") {
                                throw { name: "AbortError", message: "canceled" };
                            }
                            else if (err.code) {
                                if ((err === null || err === void 0 ? void 0 : err.code) === 401) {
                                    localStorage.removeItem("token");
                                    localStorage.removeItem("favourites");
                                    localStorage.removeItem("cart");
                                    deleteCookie("user_id");
                                    deleteCookie("hashcode");
                                    location.href = location.origin;
                                }
                                !hideErrorSnackbar && store.default.dispatch(changeSnackbarData({ message: err.message, status: "error" }));
                                throw err;
                            }
                            else {
                                !hideErrorSnackbar && store.default.dispatch(changeSnackbarData({ message: "Network Error", status: "error" }));
                                throw { name: "FetchError", code: "ERR_NETWORK", message: "Network Error" };
                            }
                            return [2];
                        });
                    }); })];
        }
    });
}); };
