import { useAppDispatch } from "Redux/hooks";
import { saveAuthToken, saveUserData } from "Redux/slices/userInfoSlice";
import { removeProductsFromLocalCartOrFavourites } from "Redux/slices/cartSlice";
import { rewriteNotifications, rewriteUnvisitedOrders } from "Redux/slices/appSlice";
export var useLogout = function () {
    var dispatch = useAppDispatch();
    return function () {
        dispatch(saveUserData(null));
        dispatch(saveAuthToken(""));
        dispatch(removeProductsFromLocalCartOrFavourites(["cart", "favourites"]));
        dispatch(rewriteNotifications({ data: [], withLocalSave: true }));
        dispatch(rewriteUnvisitedOrders(0));
    };
};
