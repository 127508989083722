var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    selectedProducts: [],
};
export var homeSlice = createSlice({
    name: "home",
    initialState: initialState,
    reducers: {
        changSelectionMarkingStatus: function (state, action) {
            var filterredArray = state.selectedProducts.filter(function (selectedProduct) { return selectedProduct.id !== action.payload.id; });
            if (state.selectedProducts.length === filterredArray.length) {
                state.selectedProducts = __spreadArray(__spreadArray([], __read(state.selectedProducts), false), [action.payload], false);
            }
            else {
                state.selectedProducts = filterredArray;
            }
        },
        rewriteSelectedProducts: function (state, action) {
            state.selectedProducts = action.payload;
        },
    },
});
export var changSelectionMarkingStatus = (_a = homeSlice.actions, _a.changSelectionMarkingStatus), rewriteSelectedProducts = _a.rewriteSelectedProducts;
export default homeSlice.reducer;
