export var personalAreaRoutes = [
    "/awards",
    "/favourites",
    "/invitations",
    "/myAddresses",
    "/myDetails",
    "/myDiscount",
    "/orders",
    "/personalArea",
    "/promocodes",
    "/purchases",
    "/sizes",
    "/subscriptions",
];
